// pages/404.js
import React from "react";
import ErrorLayout from "../components/layouts/ErrorLayout";

const Custom404 = () => (
    <ErrorLayout
        redirectUrl="/"
        redirectButtonText="Take me home"
        status={"404"} />
);

export default Custom404;

Custom404.auth = false;

// need custom 404 page because we added _error.js
// https://github.com/vercel/next.js/blob/master/errors/custom-error-no-custom-404.md
